<i18n>
	{
		"en": {
			"loginTxt": "Please Login to continue",
			"dontHave": "Don't have account ?",
			"register": "Register",
			"checkNimbuzzApp": "Please check your Nimbuzz app for verification code.",
			"forgotPass": "Forgot Password?",
			"confirm": "Confirm",
			"enterNumber": "Enter mobile number",
			"enterPass": "Enter password",
			"forgotPass": "Forgot Password?",
			"login": "Login",
			"verifyActivationCode": "Please verify your activation code"
		},
		"np":{
			"loginTxt": "अगाडि बढ्नको लागि कृपया लगइन गर्नुहोस्",
			"dontHave": "खाता छैन?",
			"register": "दर्ता गर्नुहोस्",
			"checkNimbuzzApp": "प्रमाणीकरण कोडको लागि कृपया आफ्नो Nimbuzz एप जाँच गर्नुहोस्।",
			"forgotPass": "पासवर्ड बिर्सनुभयो?",
			"confirm": "पुष्टि गर्नुहोस्",
			"enterNumber": "मोबाइल नम्बर लेख्नुहोस्",
			"enterPass": "पासवर्ड लेख्नुहोस्",
			"forgotPass": "पासवर्ड बिर्सनुभयो?",
			"login": "लगइन",
			"verifyActivationCode": "कृपया आफ्नो सक्रियता कोड प्रमाणित गर्नुहोस्"
			}
	}
</i18n>
<template lang="pug">
	.hero-banner
		.hero-banner-content
			.container
				.login-modal.login-box
					.modal-header
						h6.font-weight-bold {{ $t("loginTxt") }}
						small {{ $t("dontHave") }}
							router-link.text-danger.ml-2(to='/register') {{ $t("register") }}
					.modal-body
						.show-message(v-if='message') {{message}}
						.row
							.col-sm-12.col-lg-7(v-if="isWebAuthenticationRequired")
								form(@submit.prevent='validateWebAuthenticationCode')
									.form-group
										.input-group
											input.form-control(type="number"
												v-model="webAuthenticationCode"
												:placeholder='$t("verifyActivationCode")'
												required
												minlength="6")
										small.form-text {{ $t("checkNimbuzzApp") }}
									small.forgot-password
										router-link(to='/recover') {{ $t("forgotPass") }}
									.text-center.mt-4
										button.btn.btn-danger.btn-block(:disabled='loading') 
											b-spinner.text-center(small v-if='loading')
											.text-center(v-else) {{ $t("confirm") }}
							.col-sm-12.col-lg-7(v-else)
								form(@submit.prevent='authenticate')
									.form-group.input-group
										.input-group-prepend
											.input-group-text +977
										input.form-control( type="tel"
											pattern="[0-9]+"
											v-model="phoneNumber"
											maxlength="10"
											:placeholder="$t('enterNumber')"
											required
											autofocus)
									.form-group.input-group
										input.form-control(type="password"
											v-model="normalPassword"
											:placeholder="$t('enterPass')"
											required
											minlength="6")
									small.forgot-password
										router-link(to='/recover') {{ $t("forgotPass") }}
									.text-center.mt-4
										button.btn.btn-danger.btn-block(:disabled='loading') 
											b-spinner.text-center(small v-if='loading')
											.text-center(v-else) {{ $t("login") }}


		.hero-silhouette
		
</template>

<script>
import {
	getSecret,
	makePasswordHash,
	REMOTE_SERVER_1,
	prepareHeaders,
} from "@/helpers/general";
import uuid from "uuid";
import axios from "@/axios";
export default {
	name: "login",
	data() {
		return {
			user: {
				userid: "",
				password: "",
				organizationid: "kuraakani",
				secret: "",
				UUID: "",
			},
			webAuthenticationCode: "",
			normalPassword: "",
			phoneNumber: "",
			error: null,
			loading: false,
			message: "",
			isWebAuthenticationRequired: false,
		};
	},
	async mounted() {
		const intent = sessionStorage.getItem("intent");
		if (intent && !window.location.search) {
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set("intent", intent);
			window.location.search = urlParams;
		}
		/* let credential = await navigator.credentials.get({
			publicKey: {
				challenge: new Uint8Array([139, 66, 181, 87, 7, 203]),
				allowCredentials: [
					{
						type: "public-key",
						id: new Uint8Array([64, 66, 25, 78, 168, 226, 174])
					}
				],
				userVerification: "required"
			}
		});
		console.log(credential); */
	},
	methods: {
		authenticate() {
			this.loading = true;
			localStorage.removeItem("user");
			let UUID = localStorage.getItem("_UUID") || uuid();
			localStorage.setItem("_UUID", UUID);
			this.user = {
				...this.user,
				secret: getSecret(),
				userid: "+977" + this.phoneNumber,
				password: makePasswordHash(this.normalPassword),
				UUID: UUID,
			};
			axios
				.post(REMOTE_SERVER_1 + "user/signin", this.user)
				.then((r) => {
					if (r.data.data) {
						this.message = "";
						this.isWebAuthenticationRequired = true;
						this.loading = false;
					} else {
						this.message = "Unable to log you in.";
						this.loading = false;
					}
				})
				.catch((_) => { });
		},
		validateWebAuthenticationCode() {
			this.loading = true;
			localStorage.removeItem("user");
			let UUID = localStorage.getItem("_UUID") || uuid();
			localStorage.setItem("_UUID", UUID);
			this.user = {
				...this.user,
				secret: getSecret(),
				userid: "+977" + this.phoneNumber,
				password: makePasswordHash(this.normalPassword),
				UUID: UUID,
				webAuthenticationCode: this.webAuthenticationCode,
			};
			axios
				.post(REMOTE_SERVER_1 + "user/signin/validate", this.user)
				.then((r) => {
					if (r.data.data) {
						sessionStorage.setItem("_token", r.data.data.newToken);
						prepareHeaders(r.data.data.newToken, axios);
						this.$store.commit("SET_USER", r.data.data.user);
						localStorage.setItem(
							"user",
							JSON.stringify({
								name: r.data.data.user.name,
								imageURL: r.data.data.user.avatar.picture.nameOnServer,
								uid: r.data.data.user._id,
							})
						);
						const intent = sessionStorage.getItem("intent");
						if (intent) {
							this.$router.push(`vendor/payment?intent=${intent}`);
							sessionStorage.removeItem("intent");
							return;
						}
						location.reload();
					} else {
						this.message = "Unable to log you in.";
						this.loading = false;
					}
				})
				.catch((_) => { });
		},
	},
};
</script>

<style scoped>
.error {
	text-align: center;
	color: red;
}
</style>
